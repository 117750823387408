<script>
  import Rep from "./Rep.svelte";
  import { repMapper } from "./rep-mapper";

  let zipCode = "";
  $: reps = repMapper(zipCode);
</script>

<h1>Sales Reps</h1>

<input bind:value={zipCode} placeholder="Enter your ZIP Code" />

{#if reps.length === 0}
  <p>Please enter your zip code to locate a sales rep for your area...</p>
{:else}
  {#each reps as rep}
    <Rep>
      <span slot="name">
        {rep.name}
      </span>

      <span slot="telephone">
        <a href="tel:{rep.telephone}">{rep.telephone}</a>
      </span>

      <span slot="email">
        <a href="mailto:{rep.email}">{rep.email}</a>
      </span>
    </Rep>
  {/each}
{/if}
