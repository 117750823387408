import ZipTable from './zip-to-rep.json'
import Reps from './reps.json'

function repMapper(zipCode) {

    let reps = []

    const mappings = ZipTable.filter( m => m.zipCode == zipCode )
    mappings.forEach(mapping => {
        const repId = mapping.repId
        if(repId in Reps){
            reps.push(Reps[repId])
        } else {
            console.error(`Missing repId '${repId}' referenced in 'zip-to-rep.json' that does not appear in 'reps.json'`)
        }
    });

    return reps
}

export { repMapper }